export enum PermissionsType {
  P_DMES = 'p-dmes',
  P_CIRCUIT_MANAGE = 'p-circuitManage',
  //回路列表
  P_CIRCUIT_LIST = 'p-circuitPropertyConfig',
  //编辑回路
  P_UPDATECIRCUIT = 'p-updateCircuit',
  //创建回路
  P_CREATECIRCUIT = 'p-createCircuit',
  //创建回路按钮
  B_CREATECIRCUIT = 'b-createCircuit',
  //删除回路按钮
  B_DELETECIRCUIT = 'b-deleteCircuit',
  //编辑回路按钮
  B_UPDATECIRCUIT = 'b-updateCircuit',
  // 分时重计算
  B_CIRCUITRERUNELECTRICITYSTATICS = 'b-circuitRerunElectricityStatics',

  //电价列表
  p_E_PRICE_LIST = 'p-ePriceList',
  //编辑电价
  P_UPDATEEPRICE = 'p-updateEPrice',
  //创建电价
  P_CREATEEPRICE = 'p-createEPrice',
  //创建电价按钮
  B_CREATEEPRICE = 'b-createEPrice',
  //删除电价按钮
  B_DELETEEPRICE = 'b-deleteEPrice',
  //编辑电价按钮
  B_UPDATEEPRICE = 'b-updateEPrice',

  // 微网储能策略管理
  P_BSA_STRATEGY_MANAGE = 'p-bsaStrategyManage',
  // 储能策略
  P_STRATEGY_LIST = 'p-strategyList',
  // 储能策略模板
  P_STRATEGY_TEMPLATE = 'p-strategyTemplate',
  // 新建策略模板按钮
  B_CREATE_STRATEGY_TEMPLATE = 'b-createStrategyTemplate',
  // 编辑策略模板按钮
  B_UPDATE_STRATEGY_TEMPLATE = 'b-updateStrategyTemplate',
  // 新建策略模板
  P_CREATE_STRATEGY_TEMPLATE = 'p-createStrategyTemplate',
  // 编辑策略模板
  P_UPDATE_STRATEGY_TEMPLATE = 'p-updateStrategyTemplate',
  // 查看策略模板
  P_STRATEGY_INFO = 'p-strategyInfo',
  // 就地策略管理
  P_CBESS_STRATEGY_MANAGE = 'p-cbessStrategyManage',
  // 就地策略编辑按钮
  B_UPDATE_CBESS_STRATEGY = 'b-updateCbessStrategy',
  // 编辑就地策略
  P_UPDATE_CBESS_STRATEGY = 'p-updateCbessStrategy',
  // 就地策略查看
  P_CBESS_STRATEGY_INFO = 'p-cbessStrategyInfo',
  // 投放就地策略
  P_CREATE_CBESS_STRATEGY = 'p-createCbessStrategy',
  // 储能曲线
  P_MONITOR_LINE_STORAGE = 'p-energyStorageMonitorLine',
  // 线损相关
  P_LINE_LOSS = 'p-lineLoss',
  // 线损报警规则列表
  P_LINE_LOSS_ALARM_RULE_LIST = 'p-lineLossAlarmRuleList',
  // 线损报警日志
  P_LINE_LOSS_ALARM_LOG_LIST = 'p-lineLossAlarmLogList',
  // 添加/编辑线损配置
  B_LINE_LOSS_CONFIG_EDIT_ADD = 'b-circuitLineLossConfigEdit',
  // 移除线损配置
  B_LINE_LOSS_CONFIG_REMOVE = 'b-circuitLineLossConfigEdit',
  // 新建报警规则
  B_LINE_LOSS_ALARM_RULE_ADD = 'b-lineLossAlarmRuleAdd',
  // 批量新建报警规则
  B_LINE_LOSS_ALARM_RULE_ADD_MORE = 'b-lineLossAlarmRuleBatchAdd',
  // 修改报警规则
  B_LINE_LOSS_ALARM_EDIT = 'b-lineLossAlarmRuleEdit',
  // 删除报警规则
  B_LINE_LOSS_ALARM_REMOVE = 'b-lineLossAlarmRuleRemove',
  // 报警规则启用、禁用
  B_LINE_LOSS_ALARM_ENABLE = 'b-lineLossAlarmRuleEnable',

  // 储能运维
  P_ENERGY_STORAGE = 'p-energyStorage',
  // 储能基础设置
  P_ENERGY_STORAGE_BASIC_MANAGE = 'p-energyStorageBasicManage',
  // 能源资产门户
  P_ENERGY_ASSERT_PORTAL = 'p-energyAssertPortal',
  // 阵列管理
  P_BSA_MANAGE = 'p-bsaManage',
  // 阵列编辑按钮
  B_UPDATE_BSA = 'b-updateBsa',
  // 配置与阵列下发
  B_CONFIG_DRIVER = 'b-bsaUIConfig',
  // 数据点镜像
  B_BSAPOINTMIRROR = 'b-bsaPointMirror',
  // 就地端配置和下发记录同上(配置与阵列下发)
  // 阵列查看按钮
  B_BSA_INFO = 'b-bsaInfo',
  // 阵列编辑
  P_UPDATE_BSA = 'p-updateBsa',
  // 阵列查看
  P_BSA_INFO = 'p-bsaInfo',
  // 批量编辑电池簇数采
  P_BATCH_UPDATE_RACK_EDGE_DEVICE = 'p-batchUpdateRackEdgeDevice',
  // 批量编辑电池组数采
  P_BATCH_UPDATE_PACK_EDGE_DEVICE = 'p-batchUpdatePackEdgeDevice',
  //阵列状态按钮
  B_UPDATEBSASTATUS = 'b-updateBsaStatus',
  //批量编辑阵列按钮
  B_BATCHUPDATEBSA = 'b-batchUpdateBsa',

  // 储能阵列管理（新）
  P_BSA_MANAGE_NEW = 'p-bsaManageNew',
  // 编辑储能基础信息按钮（新）
  B_BSA_INFO_EDIT_NEW = 'b-bsaInfoEditNew',
  // 编辑储能基础信息（新）
  P_BSA_INFO_EDIT_NEW = 'p-bsaInfoEditNew',
  // 查看储能基础信息（新）
  P_BSA_INFO_VIEW_NEW = 'p-bsaInfoViewNew',
  // 编辑储能数采信息（新）
  P_BSA_DEVICE_Edge_INFO_NEW = 'p-bsaDeviceEdgeInfoNew',
  // 查看阵列数采配置（新）
  P_BSA_DEVICE_Edge_INFO_VIEW_NEW = 'p-bsaDeviceEdgeInfoViewNew',
  // 编辑储能阵列信息按钮（新）
  B_BSA_ARRAY_EDIT_NEW = 'b-bsaArrayEditNew',
  // 批量编辑电池簇采集点按钮（新）
  B_BATCH_UPDATE_RACK_EDGE_DEVICE_NEW = 'b-batchUpdateRackEdgeDeviceNew',
  // 批量编辑电池组采集点按钮（新）
  B_BATCH_UPDATE_PACK_EDGE_DEVICE_NEW = 'b-batchUpdatePackEdgeDeviceNew',
  // 批量编辑电芯采集点按钮（新）
  B_BATCH_UPDATE_CELL_EDGE_DEVICE_NEW = 'b-batchUpdateCellEdgeDeviceNew',
  // 编辑储能配套设备按钮（新）
  B_BSA_SUPPORT_EQUIP_EDIT_NEW = 'b-bsaSupportEquipEditNew',
  // 编辑储能数采信息按钮（新）
  B_BSA_DEVICE_EDGE_INFO_NEW = 'b-bsaDeviceEdgeInfoNew',
  // 编辑成本信息按钮（新）
  B_BSA_COST_INFO_EDIT_NEW = 'b-bsaCostInfoEditNew',
  // 储能调度设置按钮（新）
  B_UED_SET = 'b-uedSet',
  // 启用/禁用储能站按钮（新）
  B_BSA_ENABLE_DISABLE_NEW = 'b-bsaEnableDisableNew',
  // 查看成本信息按钮（新）
  B_BSA_COST_INFO_VIEW_NEW = 'b-bsaCostInfoViewNew',

  // 能源数据
  P_ENERGY_DATA = 'p-energyData',
  // 回路数据
  P_CIRCUIT_DATA = 'p-circuitData',
  // 用电分析
  P_ELECTRICITY_CONSUMPTION_ANALYSIS = 'p-powerConsumptionAnalysis',

  // 节能管理配置
  P_ENERGY_SAVING = 'p-energySaving',
  // 编辑节能管理
  B_ENERGY_SAVING_EDIT = 'b-energySavingEdit',

  // 回路配置
  P_CIRCUIT_CONFIG = 'p-circuitConfig',
  // 光伏站运维
  P_PV_OPERATION = 'p-pvOperation',
  // 光伏基础设置
  P_PV_STATION_BASIC_CONFIG = 'p-pvStationBasicConfig',
  // 光伏站管理
  P_PV_STATION_MANAGE = 'p-pvStationManage',
  // 启用
  B_PV_STATION_START = 'b-pvStationStart',
  // 停用
  B_PV_STATION_STOP = 'b-pvStationStop',
  // 编辑基础信息
  B_PV_BASIC_EDIT = 'b-pvBasicMessageEdit',
  // 编辑阵列信息
  B_PV_ARRAY_EDIT = 'b-pvArrayEdit',
  // 编辑高压设备
  B_PV_HIGH_VOLTAGE_EDIT = 'b-pvHighVoltageEdit',

  // 储能数据
  B_ENERGY_STORAGE_DATA = 'b-energyStorageData',
  // 温度实时曲线页面
  P_ENERGY_REAL_TIME_LINE = 'p-energyStorageRealTimeLine',

  // 进线总览
  P_INCOMINGLINEOVERVIEW = 'p-incomingLineOverview',
  // 进线数据查看
  B_INCOMINGLINEDATA = 'b-incomingLineData',
  // 查看拓扑
  B_INCOMINGLINETOPOLOGY = 'b-incomingLineTopology',
  // 回路拓扑图按钮
  B_CIRCUITTOPOLOGY = 'b-circuitTopology',
  // 回路拓扑图页面
  P_CIRCUITTOPOLOGY = 'p-circuitTopology',
  // 配电柜管理
  P_CABINET_MANAGE = 'p-distributionCabinetManage',
  // 新建配电柜
  B_CABINET_ADD = 'b-distributionCabinetAdd',
  // 编辑配电柜
  B_CABINET_EDIT = 'b-distributionCabinetModify',
  // 删除配电柜
  B_CABINET_DELETE = 'b-distributionCabinetDelete',
  // 导出回路列表
  B_CIRCUIT_OUTPUT = 'b-circuitOutput',
  // 投放记录
  B_STRATEGY_DELIVER_LOG = 'b-strategyDeliverLog',
  B_STRATEGY_COPY = 'b-strategyCopy',

  // 报警记录
  P_ALARM_RECORD = 'p-dmesAlarmRecord',
  // 报警记录详情
  P_ALARM_RECORD_DETAIL = 'p-dmesAlarmRecordDetail',

  // 创建工单, 查看工单
  B_WORKER_ORDER_CREATE = 'b-dmesWorkOrderCreate',
  // 报警工单
  P_QL_WORK_ALARM = 'p-qlWorkOrderAlarm',

  // 储能概览
  P_STORAGE_OVERVIEW = 'p-assetManageSummaryStation',

  // pv 查看成本信息
  B_PV_COSTINFO_VIEW = 'b-pvCostInfo',
  // pv 编辑成本信息
  B_PV_COSTINFO_EDIT = 'b-pvCostInfoEdit',
  // bsa 查看成本信息
  B_BSA_COSTINFO_VIEW = 'b-bsaCostInfo',
  // bsa 编辑成本信息
  B_BSA_COSTINFO_EDIT = 'b-bsaCostInfoEdit',

  // 资产报警看板页面权限
  P_ASSET_KANBAN_PAGE = 'p-assetFaultKanban',

  // 资产报警看板 创建工单
  P_ASSET_KANBAN_CREATE_ORDER = 'b-assetOperationAndMaintenanceCreateOrder',
  // 发起工单权限1
  B_SUBMIT_ORDER = 'b-zcinitiateworkorder',
  // 发起工单权限2
  B_SUBMIT_ORDER_OTHER = 'b-wwinitiateworkorder',
  // 储能资产概览GIS
  P_ENERGYGISSUMMARY = 'p-energyGisSummary',

  // 光伏整体运维看板
  P_PVSTATIONDASHBOARD = 'p-pvStationDashBoard',
  // 储能整体运维看板
  P_BSADASHBOARD = 'p-bsaDashBoard',

  // 能源资产概览
  P_ENERGYASSETSUMARY = 'p-energyAssetSumary',

  // 充电站管理
  P_CHARGINGMANAGE = 'p-chargingManage',

  // 查看成本信息
  B_CHARGINGVIEWCOST = 'b-chargingViewCost',

  // 编辑成本信息
  B_CHARGINGEDITCOST = 'b-chargingEditCost',

  // 启用/停用
  B_CHARGINGSTATUSCHANGE = 'b-chargingStatusChange',

  // 编辑按钮
  B_CHARGINGEDIT = 'b-chargingEdit',

  // 查看按钮
  B_CHARGINGVIEW = 'b-chargingView',

  // 编辑页面
  P_CHARGINGEDIT = 'p-chargingEdit',
  // 查看页面
  P_CHARGINGVIEW = 'p-chargingView',

  // 能源资产项目收益
  P_PROJECTINCOMESTATISTICALANALYSIS = 'p-projectIncomeStatisticalAnalysis',
  // 服务端报警
  P_SERVERALARM = 'p-serverAlarm',
  // 储能站远程控制按钮
  B_REMOTE_CONTROL = 'b-bsaRemoteControl',
  // 储能站远程控制页面
  P_REMOTE_CONTROL = 'p-bsaRemoteControl',
  // 储能站远程控制日志
  P_REMOTE_CONTROL_LOG = 'p-bsaRemoteControlLogs',
  // 控制指令配置按钮
  B_REMOTE_CONTROL_SET = 'b-bsaRemoteControlSet',
  // 控制指令页面
  P_REMOTE_CONTROL_SET = 'p-bsaRemoteControlSet',

  // 储能分时电量报表
  P_BSATOUINCOMEREPORT = 'p-bsaTOUIncomeReport',
  // 电量统计tab
  B_ELECTRICITY_STATISTICS_TAB = 'b-ElectricityStatisticsTab',
  // 收益列展示
  B_INCOME_COLUMN_PRESENTATION = 'b-IncomeColumnPresentation',
  // 储能抄表记录tab
  B_BSA_METER_READING_RECORD = 'b-BSAMeterReadingRecord',
  // 隐藏示数*倍率列按钮
  B_BSA_HIDDEN_INDICATOR_MULTIPLIED_BY_MULTIPLIER = 'b-BSAHiddenIndicatorMultipliedByMultiplier',
  // 重新抄表按钮
  B_METER_READING = 'b-MeterReading',

  // 电池热力图
  P_ENERGYSTORAGEHEATMAP = 'p-energyStorageHeatMap',
  // 储能运营概览
  P_BSAASSETOPSSUMMARY = 'p-bsaAssetOpsSummary',

  // 储能历史数据
  P_HISTORICAL_ENERGY_STORAGE_DATA = 'p-HistoricalEnergyStorageData',
  // 储能实时监控页面
  P_REALTIME_STORAGE = 'p-RealTimeEnergyStorageData',
  // 储能实时监控数据点远控
  B_REALTIME_POINT_REMOTE = 'b-DataPointRemoteControl',

  // 分类配置
  B_REALTIME_CONFIG_DATA = 'b-ConfigureDataClassification',

  // 光伏离散率分析设置
  P_PVA_DISPERSION_SET = 'p-pvaDispersionSet',
  // 光伏站点绩效配置
  P_PVA_PERFORMANCE_SET = 'p-pvaPerformanceSet',
  // 光伏站点绩效概览
  P_PVA_PERFORMANCE_OVERVIEW = 'p-pvaPerformanceOverview',

  // 光伏单站看板
  P_STATION_DASHBOARD = 'p-pvaMonitorDB',
  // 光伏电量收益报表
  P_PVA_TOU_INCOME_REPORT = 'p-pvaTOUIncomeReport',
  // 光伏站点运营概览
  P_PVA_ASSET_OPS_SUMMARY = 'p-pvaAssetOpsSummary',

  // 光伏单站布局图按钮
  B_PVA_LAYOUT = 'b-pvaMonitorDBLayout',

  // 光伏站预测
  P_POWERGENERATIONFORECAST = 'p-powerGenerationForecast',

  // 光伏绩效分析
  P_PVAPERFORMANCEANALYSIS = 'p-pvaPerformanceAnalysis',
}
